import addScriptTag from "@/lib/addScriptTag";
import { getSettings } from "@/lib/esite";

async function init() {
  if (window.dataLayer) return;

  const { google_analytics } = await getSettings();
  
  const measurementId = google_analytics?.measurement_id;

  if (!measurementId) return;

  const url = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

  addScriptTag(url, {}, document.head);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", measurementId);
}

export default init;
