import initModules from "./modules";

import "./components/fontawesomeLib";
import "./css/styles.css";

if (typeof window.__spilloverEsitesCommonLoaded === "undefined") {
  window.__spilloverEsitesCommonLoaded = true;

  window.addEventListener("load", () => initModules());
}
