import { useEffect, useState } from "react";

export function truncate(text, max) {
  if (text.length > max) {
    return `${text.slice(0, max)}...`;
  } else {
    return text;
  }
}

function OverlayPost({ post, style }) {
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    const fetchType = async () => {
      const response = await fetch(post.images[0], {
        method: "HEAD",
      });

      const contentType = response.headers.get("content-type");

      if (contentType.includes("video")) {
        setMediaType("video");
      } else {
        setMediaType("image");
      }
    };

    fetchType().catch(console.error);
  }, []);

  return (
    <div
      className="instagram-feed-overlay-post-container so-group so-flex-shrink-0 so-flex-grow so-relative so-h-64"
      style={style}
    >
      {mediaType === "video" ? (
        <video className="so-w-full so-h-auto" muted>
          <source src={post.images[0]} type="video/mp4" />
        </video>
      ) : (
        <img
          src={post.images[0]}
          alt="Image or Video"
          className="so-w-full so-flex-shrink-0 so-flex-grow so-h-auto so-object-fit so-min-h-[16rem]"
        />
      )}

      <div className="instagram-feed-overlay-post-overlay so-absolute so-top-0 so-bottom-0 so-right-0 so-left-0 so-h-full so-w-full so-bg-white so-opacity-0 so-transition-all so-ease-linear so-duration-100 group-hover:so-opacity-90">
        <div className="so-flex so-justify-center so-flex-col so-items-center">
          <div className="instagram-feed-overlay-post-icons so-flex so-justify-center so-mt-8">
            <div className="so-mx-2">
              <i className="fa fa-solid so-mr-1 fa-heart"></i>
              {post.likes}
            </div>
            <div className="so-mx-2">
              <i className="fa fa-solid so-mr-1 fa-comment"></i>
              {post.comments}
            </div>
          </div>
          <div className="instagram-feed-overlay-post-text so-mt-8 so-text-center so-px-10">
            {truncate(post.description, 100)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverlayPost;
