import { useEffect, useState } from "react";

import apiRequest from "@/lib/api";
import { getDomain } from "@/lib/esite";
import Carousel from "./Carousel";
import OverlayPost from "./OverlayPost";
import SharePost from "./SharePost";

const mediaList = [
  window.matchMedia("(max-width: 400px)"),
  window.matchMedia("(min-width:401px) and (max-width:700px)"),
  window.matchMedia("(min-width:701px) and (max-width:992px)"),
  window.matchMedia("(min-width:993px) and (max-width:1200px)"),
  window.matchMedia("(min-width:1201px)"),
];

const initialDisplayCards = () => {
  if (mediaList[0].matches) {
    return 1;
  } else if (mediaList[1].matches) {
    return 2;
  } else if (mediaList[2].matches) {
    return 3;
  } else if (mediaList[3].matches) {
    return 4;
  } else if (mediaList[4].matches) {
    return 5;
  }
};

function Widget({ type, numberOfPosts, slug }) {
  const [show, setShow] = useState(initialDisplayCards());
  const [posts, setPosts] = useState([]);

  let style;
  switch (show) {
    case 1:
      style = { width: "calc(100%/1)" };
      break;
    case 2:
      style = { width: "calc(100%/2)" };
      break;
    case 3:
      style = { width: "calc(100%/3)" };
      break;
    case 4:
      style = { width: "calc(100%/4)" };
      break;
    case 5:
      style = { width: "calc(100%/5)" };
      break;
    default:
      break;
  }

  mediaList.forEach((media) => {
    media.addEventListener("change", () => {
      if (mediaList[0].matches) {
        setShow(1);
      } else if (mediaList[1].matches) {
        setShow(2);
      } else if (mediaList[2].matches) {
        setShow(3);
      } else if (mediaList[3].matches) {
        setShow(4);
      } else if (mediaList[4].matches) {
        setShow(5);
      }
    });
  });

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async () => {
    const number_of_posts = numberOfPosts || 10;

    const response = await apiRequest(
      `${getDomain()}/instagram_posts?number_of_posts=${number_of_posts}&location=${slug}`,
    );
    if (!response) return;

    const fetchedPosts = response.data;
    setPosts(fetchedPosts);
  };

  return (
    <>
      {type === "images" ? (
        <Carousel show={show}>
          {posts.map((post) => (
            <OverlayPost key={post.external_id} post={post} style={style} />
          ))}
        </Carousel>
      ) : (
        <Carousel show={show}>
          {posts.map((post) => (
            <SharePost key={post.external_id} post={post} style={style} />
          ))}
        </Carousel>
      )}
    </>
  );
}

export default Widget;
