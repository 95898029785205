import axios, { CanceledError } from "axios";

import checksum from "./checksum";

// Rails' implementation sucks, so we implement it ourselves.
// SEE: https://github.com/rails/rails/blob/85edd855d0a1c4127fa5ba56c2b0c07148ce80e6/activestorage/app/javascript/activestorage/direct_upload.js
class DirectUpload {
  file;
  url;
  extraParams = {};
  checksum;
  uploadReqData;
  abortController;
  blob;

  constructor(file, url, extraParams = {}) {
    this.file = file;
    this.url = url;
    this.extraParams = extraParams;
  }

  async upload(onUploadProgress = null) {
    await this._calculateChecksum();
    await this._createBlob();
    return this._uploadBlob(onUploadProgress);
  }

  abort() {
    if (this.abortController) this.abortController.abort();
  }

  async _calculateChecksum() {
    this.checksum = await checksum(this.file);
  }

  async _createBlob() {
    this.abortController = new AbortController();

    try {
      const response = await axios({
        method: "post",
        url: this.url,
        signal: this.abortController.signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          blob: {
            filename: this.file.name,
            content_type: this.file.type || "application/octet-stream",
            byte_size: this.file.size,
            checksum: this.checksum,
          },
          ...this.extraParams,
        },
        validateStatus: (status) => status >= 200 && status < 300,
      });

      this.blob = response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  async _uploadBlob(onUploadProgress = null) {
    this.abortController = new AbortController();

    const { url, headers } = this.blob.direct_upload;

    try {
      await axios({
        method: "put",
        url,
        headers,
        responseType: "text",
        signal: this.abortController.signal,
        data: this.file,
        validateStatus: (status) => status >= 200 && status < 300,
        onUploadProgress,
      });

      return this.blob.signed_id;
    } catch (error) {
      this._handleError(error);
      return false;
    }
  }

  _handleError(error) {
    if (error instanceof CanceledError) {
      // Ignore errors thrown by aborting requests.
      return;
    } else {
      console.error(error);
      throw new Error("Error uploading file");
    }
  }
}

export default DirectUpload;
