import { Duration } from "luxon";

// Remove all properties with a value of 0 from a luxon obj.
// TODO: Review after https://github.com/moment/luxon/pull/1323 is released.
function removeZeroes(vals) {
  const newVals = {};

  for (const [key, value] of Object.entries(vals)) {
    if (value !== 0) {
      newVals[key] = value;
    }
  }

  return newVals;
}

// durationObj is a duration-like object: { seconds: 10 } or { seconds: 10, minutes: 2 }.
// SEE: https://moment.github.io/luxon/api-docs/index.html#durationfromobject
function TimeLeft(durationObj) {
  const vals = removeZeroes(
    Duration.fromObject(durationObj)
      .normalize()
      .shiftTo("hours", "minutes", "seconds")
      .toObject()
  );
  
  return Duration.fromObject(vals).toHuman({
    unitDisplay: "narrow",
    maximumFractionDigits: 0
  });
}

export default TimeLeft;
