import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSpinner,
  faCheck,
  faWarning,
  faTrash,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";

// Add icons to the library so they can be referenced in <FontAwesomeIcon />
// SEE: https://fontawesome.com/docs/web/use-with/react/add-icons#add-icons-globally
library.add(faSpinner, faCheck, faWarning, faTrash, faCloudArrowUp);
