import { useState } from "react";

const SLICE_NUMBER = 270;

function Post({ post }) {
  const [showAll, setShowAll] = useState(false);
  const [sliceText, setSliceText] = useState(SLICE_NUMBER);

  const caption =
    post.caption.length > sliceText
      ? `${post.caption.slice(0, sliceText)}...`
      : post.caption;

  const postImage = (
    <img
      id={`post-image-${post.id}`}
      className="so-w-full so-h-52 so-object-cover so-rounded-t-xl site-post-img"
      src={post?.post_image}
      alt="Post Image"
    />
  );

  const postContent = (
    <>
      <div className="so-font-bold so-mb-2 site-post-headline">
        {post.headline}
      </div>
      <p
        className="so-text-sm site-post-caption"
        dangerouslySetInnerHTML={{ __html: caption }}
      ></p>
    </>
  );

  const showAllContent = () => {
    const img = document.querySelector(`#post-image-${post.id}`);
    const content = document.querySelector(`#post-content-${post.id}`);

    if (!showAll) {
      img.style.height = "3rem";
      img.style.transition = "all 0.25s ease";

      content.style.height = "100%";
      content.style.transition = "all 0.25s ease";

      setTimeout(() => {
        setSliceText(post.caption.length);
      }, 100);
    } else {
      img.style.height = "13rem";
      img.style.transition = "all 0.25s ease";

      content.style.height = "100%";
      content.style.transition = "all 0.25s ease";

      setSliceText(SLICE_NUMBER);
    }

    setShowAll(!showAll);
  };

  const seeMore = (
    <div
      className="so-absolute so-bottom-6 so-right-6 so-text-sm so-font-semibold hover:so-text-gray-700 so-cursor-pointer"
      onClick={showAllContent}
    >
      {showAll ? "Hide" : "See more"}
    </div>
  );

  return (
    <>
      <div className="so-min-h-[30rem] so-h-full so-rounded-xl so-bg-white so-border so-border-gray-200 so-border-solid site-post-container so-relative">
        {post.referral_link ? (
          <a
            target="_blank"
            href={post.referral_link}
            rel="noreferrer"
            className="hover:so-text-black so-text-black site-post-post-main-container visited:so-text-black"
          >
            {postImage}
          </a>
        ) : (
          <>{postImage}</>
        )}
        {post.referral_link ? (
          <div
            id={`post-content-${post.id}`}
            className="so-px-6 so-py-4 site-post-header"
          >
            <a
              target="_blank"
              href={post.referral_link}
              rel="noreferrer"
              className="hover:so-text-black so-text-black site-post-post-main-container visited:so-text-black"
            >
              {postContent}
            </a>
            {post.caption.length > SLICE_NUMBER && seeMore}
          </div>
        ) : (
          <div className="so-px-6 so-py-4 site-post-header">
            {postContent} {post.caption.length > SLICE_NUMBER && seeMore}
          </div>
        )}
      </div>
    </>
  );
}

export default Post;
