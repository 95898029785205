import TimeLeft from "@/components/TimeLeft";
import FileSize from "@/components/FileSize";

function Progress({ progressObj, ...rest }) {
  const { progress, estimated, rate } = progressObj;

  let text = null;

  if (estimated) {
    text = (<><TimeLeft seconds={estimated} /> left</>);
  }

  if (rate) {
    const speed = (<><FileSize size={rate} />/s</>);
    text = text ? (<>{text} - {speed}</>) : speed;
  }

  const percent = `${(progress * 100).toFixed(2)}%`;

  return (
    <div className="so-forms-upload-progress so-italic">
      <span>{ text }</span>

      <div className="progress so-mt-2" {...rest}>
        <div style={{ width: percent }} className="progress-bar progress-bar-striped progress-bar-animated">
          { percent }
        </div>
      </div>
    </div>
  );
}

export default Progress;
