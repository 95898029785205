import { useEffect, useState } from "react";

import config from "@/config";
import useGlobalLib from "./useGlobalLib";

const recaptchaKey = config.recaptchaKey;
const recaptchaURL = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;

function useRecaptcha() {
  const [ready, setReady] = useState(false);

  const { grecaptcha, loading, error } = useGlobalLib(
    "grecaptcha",
    recaptchaURL
  );

  useEffect(() => {
    if (grecaptcha) grecaptcha.ready(() => setReady(true));
  }, [grecaptcha, setReady]);

  if (error) return { error, loading: false };
  if (loading || !ready) return { loading: true, error: false };

  const getRecaptchaToken = (action) => {
    return grecaptcha.execute(recaptchaKey, { action });
  };

  return { loading: false, error: false, getRecaptchaToken };
}

export default useRecaptcha;
