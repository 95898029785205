function Popup({ content }) {
  const background = `transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat`;

  return (
    <div
      className="modal fade"
      id="popup"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="popupLabel"
      aria-hidden="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="so-box-content so-opacity-50	so-w-4 so-h-4 so-p-1 so-text-black so-border-0 so-rounded-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ background }}
            ></button>
          </div>
          <div
            className="modal-body"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
