const defaultAttrs = {
  async: true,
  defer: true,
};

function addScriptTag(url, attrs = defaultAttrs, node = document.body) {
  const mergedAttrs = { ...defaultAttrs, ...attrs };

  const promise = new Promise((resolve, reject) => {
    let script = document.createElement("script");

    for (const key in mergedAttrs) {
      let value = mergedAttrs[key];

      if (value === true) {
        // Set boolean attributes to empty string.
        value = "";
      } else if (value === false) {
        // Skip false values.
        continue;
      }

      script.setAttribute(key, value);
    }

    function onloadHander(_, isAbort) {
      if (
        isAbort ||
        !script.readyState ||
        /loaded|complete/.test(script.readyState)
      ) {
        script.onload = null;
        script.onreadystatechange = null;

        if (isAbort) {
          reject();
        } else {
          resolve(script);
        }
      }
    }

    // It's important that the script is appended before onload and src are set.
    node.appendChild(script);

    script.onload = script.onreadystatechange = onloadHander;
    script.src = url;
  });

  return promise;
}

export default addScriptTag;
