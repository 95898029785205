import apiRequest from "./api";

function getDomain() {
  return window.location.hostname.replace("www.", "");
}

const settingsKey = "spillover-esites-settings";

async function getSettings() {
  let settings = window.sessionStorage.getItem(settingsKey);
  if (settings) return JSON.parse(settings);

  const response = await apiRequest(`${getDomain()}/settings`);
  settings = response.data;

  window.sessionStorage.setItem(settingsKey, JSON.stringify(settings));

  return settings;
}

export { getDomain, getSettings };
