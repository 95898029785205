import { createRoot } from "react-dom/client";
import Widget from "./Widget";

function init() {
  if (document.querySelector(".instagram-widget-posts") !== null) {
    const container = document.querySelector(".instagram-widget-posts");
    const root = createRoot(container);

    const numberOfPosts = container.getAttribute("data-number-posts");
    const slug = container.getAttribute("data-location");

    root.render(
      <Widget type="posts" numberOfPosts={numberOfPosts} slug={slug} />,
    );
  }

  if (document.querySelector(".instagram-widget-images") !== null) {
    const container = document.querySelector(".instagram-widget-images");
    const root = createRoot(container);

    const numberOfPosts = container.getAttribute("data-number-posts");
    const slug = container.getAttribute("data-location");

    root.render(
      <Widget type="images" numberOfPosts={numberOfPosts} slug={slug} />,
    );
  }
}

export default init;
