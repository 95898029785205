import { useEffect, useState } from "react";
import { truncate } from "./OverlayPost";

function daysAgo(post) {
  const today = new Date();
  const publishedDate = Date.parse(post.published_date);
  const diff = today - publishedDate;
  const hours = Math.round(diff / (1000 * 60 * 60));

  if (hours < 23) {
    return `${hours} HOURS AGO`;
  } else {
    return `${Math.round(diff / (1000 * 60 * 60 * 24))} DAYS AGO`;
  }
}

function SharePost({ post, style }) {
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    const fetchType = async () => {
      const response = await fetch(post.images[0], {
        method: "HEAD",
      });

      const contentType = response.headers.get("content-type");

      if (contentType.includes("video")) {
        setMediaType("video");
      } else {
        setMediaType("image");
      }
    };

    fetchType().catch(console.error);
  }, []);

  return (
    <div
      className="instagram-feed-share-post-container so-group so-flex-shrink-0 so-flex-grow so-relative so-h-[20rem]"
      style={style}
    >
      <div className="mx-2 so-border so-border-solid so-border-slate-200 so-h-full so-rounded-md so-pb-4">
        <div className="instagram-feed-share-post-header so-text-md so-flex so-py-2 so-px-6 so-justify-between so-items-center">
          <p className="so-mb-0 so-text-[0.6rem] so-text-slate-400">
            {daysAgo(post)}
          </p>
          <i className="fa-brands fab fa-solid fa-instagram"></i>
        </div>

        <div className="so-h-[10rem]">
          {mediaType === "video" ? (
            <video
              className="so-w-full so-h-auto so-object-cover so-max-h-full"
              muted
            >
              <source src={post.images[0]} type="video/mp4" />
            </video>
          ) : (
            <img
              src={post.images[0]}
              alt="Image or Video"
              className="so-w-full so-flex-shrink-0 so-flex-grow so-h-auto so-object-cover so-max-h-full"
            />
          )}
        </div>

        <div className="instagram-feed-share-post-details so-flex so-justify-between so-p-2">
          <div className="instagram-feed-share-post-icons so-flex so-text-slate-300">
            <div className="so-mx-2">
              <i className="so-mr-1 fa fa-solid fa-heart"></i>
              {post.likes}
            </div>
            <div className="so-mx-2">
              <i className="so-mr-1 fa fa-solid fa-comment"></i>
              {post.comments}
            </div>
          </div>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${post.link}`}
            className="so-text-slate-300"
            target="_blank"
          >
            <i className="so-mr-1 fa fa-solid fa-share"></i>
            <span>Share</span>
          </a>
        </div>

        <div className="instagram-feed-share-post-text so-text-xs so-p-2 so-overflow-hidden so-mt-[-0.5rem]">
          {truncate(post.description, 150)}
        </div>
      </div>
    </div>
  );
}

export default SharePost;
