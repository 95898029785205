import { useState, useEffect } from "react";

function Carousel({ children, show }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState(null);

  useEffect(() => {
    setLength(children.length);
  }, [children]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <div className="instagram-feed-carousel-base so-w-full so-flex so-flex-col">
      <div className="so-flex so-w-full so-relative">
        {currentIndex > 0 && (
          <button
            className="instagram-feed-carousel-button focus:so-outline-none active:so-outline-none so-h-fit so-absolute so-bottom-2 so-opacity-40 hover:so-opacity-100 so-left-1 so-z-10 so-text-4xl so-font-bold so-bg-transparent so-border-none"
            onClick={prev}
          >
            <i className="fas fa-arrow-circle-left"></i>
          </button>
        )}
        <div
          className="so-overflow-hidden so-w-full so-h-full"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`so-flex so-transition-all so-ease-linear`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {currentIndex < length - show && (
          <button
            className="instagram-feed-carousel-button focus:so-outline-none active:so-outline-none so-h-fit so-absolute so-bottom-2 so-opacity-40 hover:so-opacity-100 so-right-1 so-z-10 so-text-4xl so-font-bold so-bg-transparent so-border-none"
            onClick={next}
          >
            <i className="fas fa-arrow-circle-right"></i>
          </button>
        )}
      </div>
    </div>
  );
}

export default Carousel;
