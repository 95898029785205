import Icon from "@/components/Icon";
import FileSize from "@/components/FileSize";
import ProgressIcon from "./ProgressIcon";
import Progress from "./Progress";

function RemoveButton({ onClick, ...rest }) {
  return (
    <span {...rest} onClick={onClick}>
      <Icon icon="fa-solid fa-trash" className="so-text-gray-400 hover:so-text-red-600 so-cursor-pointer" />
    </span>
  )
}

function FileUpload({ fileUpload, fieldName, multiple, remove }) {
  const inputName = multiple ? `${fieldName}[]` : fieldName;

  return (
    <div className="so-forms-upload-file so-my-4">
      <p className="so-forms-upload-file-details m-0">
        <ProgressIcon fileUpload={fileUpload} />
        <span>
          { fileUpload.name } (<FileSize size={fileUpload.progress.total} />)
        </span>
        <RemoveButton className="so-ml-2" onClick={remove} />
      </p>

      <p className="so-forms-upload-error so-text-red-600 so-m-0">
        {fileUpload.error ? `Error: ${fileUpload.error}` : null}
      </p>

      {fileUpload.complete ? (
        <input type="hidden" name={inputName} value={fileUpload.blobId} />
      ) : null}

      {!fileUpload.complete && !fileUpload.error && (
        <Progress progressObj={fileUpload.progress} />
      )}
    </div>
  );
}

export default FileUpload;
