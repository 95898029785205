import axios from "axios";

import config from "@/config";
import { getDomain } from "./esite";

const client = axios.create({
  baseURL: config.baseApiUrl,
  headers: {
    "Spillover-Customer-Domain": getDomain(),
  },
});

function apiRequest(options) {
  try {
    return client.request(options);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default apiRequest;
