import SparkMD5 from "spark-md5";

const fileSlice =
  File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

const chunkSize = 2097152; // 2MB

function checksum(file) {
  const chunkCount = Math.ceil(file.size / chunkSize);
  let chunkIndex = 0;

  const md5Buffer = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  const readNextChunk = () => {
    if (chunkIndex < chunkCount || (chunkIndex == 0 && chunkCount == 0)) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const bytes = fileSlice.call(file, start, end);

      fileReader.readAsArrayBuffer(bytes);
      chunkIndex++;
      return true;
    } else {
      return false;
    }
  };

  readNextChunk();

  return new Promise((resolve, reject) => {
    fileReader.addEventListener("load", (e) => {
      md5Buffer.append(e.target.result);

      if (!readNextChunk()) {
        const binaryDigest = md5Buffer.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    });

    fileReader.addEventListener("error", () => {
      reject(new Error(`Error reading ${file.name} for checksum`));
    });
  });
}

export default checksum;
