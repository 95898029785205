import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import luxonPlugin, { toLuxonDateTime } from "@fullcalendar/luxon3";
import { DateTime } from "luxon";

import config from "@/config";
import { getDomain } from "@/lib/esite";

const calendarStyles = `
  <style>
    .fc-daygrid-event-harness {
      cursor: pointer;
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0 !important;
    }

    .fc-view {
      top: 100px !important;
    }

    .locations-filter-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
    }

    .filter-by-location {
      margin-bottom: 0;
      font-weight: 600;
    }

    .locations-filter-list {
      display: flex;
      list-style: none;
      padding: 0;
    }

    .calendar-location-checkbox {
      width: 1.4rem;
      height: 1.4rem;
      vertical-align: middle;
      -webkit-appearance: none;
      background: none;
      cursor: pointer;
      margin: 0 !important;
      margin-bottom: 2px !important;
      display: inline-block !important;
      border-radius: 25%;
    }

    .calendar-location-checkbox::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
  } 

  .calendar-location-checkbox:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  #subscribe-button {
    background: #6c757d;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  #subscribe-button:hover {
    background: #5c636a;
  }
  </style>
`;

const changeProtocol = (url, newProtocol) => {
  if (url.includes("http")) {
    return url.replace("http", newProtocol);
  } else if (url.includes("https")) {
    return url.replace("https", newProtocol);
  }
};

const checkIfEventIsSingleDay = (args) => {
  if (args.event.allDay) {
    args.event.setProp("display", "background");
  }

  if (args.event.extendedProps.no_span_over_days) {
    const luxonEndOfDay = DateTime.fromISO(args.event.startStr, {
      zone: "UTC",
    });

    const eventEndSameDay = luxonEndOfDay.endOf("day").toISO();

    args.event.setEnd(eventEndSameDay);
  }
};

const applyAllDayEventStyle = (args) => {
  let html;
  if (args.event.allDay) {
    html = `
      <div style="display: flex; justify-content: center; color: white; align-items: center; text-align: center; height: 100%;">
        <span>${args.event.extendedProps.description}</span>
      </div>
    `;
  } else {
    const startTime = DateTime.fromISO(args.event.startStr, { zone: "UTC" });
    const formattedTime = startTime.toFormat("ha");

    html = `
    <div>
      <span>${formattedTime}</span>
      <span>${args.event.title}</span>
    </div>
  `;
  }
  return { html };
};

const addCalendarEventsToPage = () => {
  const calendarEl = document.querySelector(".events-calendar");
  calendarEl.innerHTML = "";

  const styleTag = document.createElement("style");
  styleTag.innerHTML = calendarStyles;
  calendarEl.append(styleTag);

  const siteDomain = getDomain();
  const apiEndpoint = config.baseApiUrl;
  const subscribeUrl = `${apiEndpoint}/${siteDomain}/calendar/subscribe.ics`;
  const webcalUrl = changeProtocol(subscribeUrl, "webcal");

  const handleEventClick = ({ jsEvent, event }) => {
    jsEvent.preventDefault();
    if (event.allDay) return null;

    const timeZone = calendar.getOption("timeZone");
    const modalEl = document.getElementById("modal");
    const $modalEl = $(modalEl);

    const realEndTime = new Date(event.extendedProps.real_end_time);
    const luxonStart = toLuxonDateTime(event.start, calendar);
    const luxonEnd = toLuxonDateTime(realEndTime, calendar);
    const eventStart = luxonStart.setZone(timeZone).toFormat("ccc, DD, t");
    const eventEnd = luxonEnd.setZone(event.extendedProps.timezone_IANA);

    const sameDayEvent = luxonStart.hasSame(luxonEnd, "day");

    let title;
    if (sameDayEvent) {
      title = `
          <span style="font-size: 1.4rem;">${event.title}</span><br>
          <span style="font-size: 1rem; font-weight: 400;">(${eventStart} to ${eventEnd.toFormat(
            "t",
          )})</span>
        `;
    } else {
      title = `
          <span style="font-size: 1.4rem;">${event.title}</span><br>
          <p style="margin-bottom: 0; font-size: 1rem; font-weight: 400;">Start: ${eventStart}</p>
          <p style="margin-bottom: 0; font-size: 1rem; font-weight: 400;">End: ${eventEnd.toFormat(
            "ccc, DD, t",
          )}</p>
        `;
    }

    $modalEl.find(".modal-title").html(title);
    $modalEl.find(".modal-body").html(event.extendedProps.description);

    let footer = `
      <button type="button" class="btn"><a href="${apiEndpoint}/${siteDomain}/calendar_events/${event.id}/download_ical">Add to my calendar</a></button>
      <button type="button" class="btn btn-secondary modal-dismiss" data-bs-dismiss="modal">Close</button>
    `;

    if (event.url) {
      footer = `
        <a href="${event.url}" target="_blank" class="btn btn-primary">
          <i class="fal fa-external-link"></i> Open event link
        </a>
        ${footer}
      `;
    }

    $modalEl.find(".modal-footer").html(footer);

    $("#modal").modal("show");

    $(".modal-dismiss").click(function () {
      $("#modal").modal("hide");
    });
  };

  const addWrapperBetweenButtonsAndCalendar = () => {
    const element = document.querySelector(".fc-view-harness");

    const divEl = document.createElement("div");
    divEl.style.display = "flex";
    divEl.style.justifyContent = "space-between";
    divEl.style.alignItems = "baseline";
    divEl.style.marginTop = "1rem";
    divEl.setAttribute("id", "wrapper-timezone");

    element.prepend(divEl);
  };

  const defaultView =
    document.querySelector(".events-calendar").dataset.defaultview;
  const eventsURL = `${config.baseApiUrl}/${siteDomain}/calendar_events`;
  const eventsLocalTimezone = getEventsTimeZone();
  const localTimezone = DateTime.now().zoneName;

  const calendar = new Calendar(calendarEl, {
    timeZone: "UTC",
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      rrulePlugin,
      luxonPlugin,
    ],
    headerToolbar: {
      left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      center: "title",
      right: "prev,next today",
      bottom: "title",
    },
    height: 850,
    initialView: defaultView,
    events: eventsURL,
    eventSourceSuccess: function (content) {
      const events = convertEventsToLocationTZ(content);

      const filteredEvents = events.filter(
        (event) => event.display_on_calendar,
      );

      return filteredEvents;
    },
    eventContent: (args) => {
      return applyAllDayEventStyle(args);
    },
    dayMaxEvents: true,
    eventDisplay: "block",
    dayMaxEventRows: 1,
    eventTimeFormat: {
      hour: "numeric",
      minute: "2-digit",
    },
    eventDidMount: (args) => {
      checkIfEventIsSingleDay(args);
    },
    eventClick: handleEventClick,
    fixedWeekCount: false,
  });

  calendar.render();

  addWrapperBetweenButtonsAndCalendar();
  compareTimezones(eventsLocalTimezone, localTimezone);
  hasMultipleLocation();
  addModalToPage();
  addSubscribeButton();

  async function hasMultipleLocation() {
    const locations = await fetchLocations();
    if (locations.length > 1) {
      displayLocationsFilter();
    }
  }

  function addSubscribeButton() {
    const element = document.querySelector("#wrapper-timezone");
    const button = document.createElement("button");
    button.classList.add("subscribe-button", "btn", "btn-outline-secondary");

    button.innerText = "Subscribe";
    element.append(button);

    const buttonEl = document.querySelector(".subscribe-button");

    buttonEl.addEventListener("click", showSubscribeModal);
  }

  function showSubscribeModal() {
    const modalEl = document.getElementById("modal");
    const $modalEl = $(modalEl);

    const title = `
      <span>Subscribe to The Calendar</span>
    `;

    const body = `
      <div style="display: flex; justify-content: center; margin-bottom: 1rem">
        <a href=${webcalUrl} id="subscribe-button">Subscribe</a>
      </div>
      <p>
        To subscribe to the Campaign Calendar, click on the button above or copy the link below and follow the instructions of 
        your calendar software (Google Calendar, Outlook, etc) on how to subscribe to a calendar.
      </p>

      <a style="text-decoration: none; color: black; font-weight: 500;">${subscribeUrl}</a>
      <span class="copy-to-clipboard glyphicon glyphicon-copy" style="cursor: pointer"></span>
    `;

    const footer = `
      <button type="button" class="btn btn-secondary modal-dismiss" data-bs-dismiss="modal">Close</button>
    `;

    $modalEl.find(".modal-title").html(title);
    $modalEl.find(".modal-body").html(body);
    $modalEl.find(".modal-footer").html(footer);

    $("#modal").modal("show");

    $(".modal-dismiss").click(function () {
      $("#modal").modal("hide");
    });

    const copyIconEl = document.querySelector(".copy-to-clipboard");
    copyIconEl.addEventListener("click", function () {
      navigator.clipboard.writeText(subscribeUrl);
    });
  }

  function addModalToPage() {
    const modalDiv = document.createElement("div");
    modalDiv.setAttribute("class", "modal fade");
    modalDiv.setAttribute("id", "modal");
    modalDiv.setAttribute("tabindex", "-1");
    modalDiv.setAttribute("role", "dialog");
    modalDiv.setAttribute("aria-labelledby", "eventModal");

    const modalMarkup = `
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title fs-5" id="eventModal">Modal title</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    `;

    modalDiv.innerHTML = modalMarkup;

    document.body.append(modalDiv);
  }

  async function fetchLocations() {
    const response = await fetch(eventsURL);
    const events = await response.json();

    const filteredEvents = await events.filter(
      (event) => event.display_on_calendar,
    );

    const locations = await filteredEvents.map((e) => {
      return {
        locationName: e.location_name,
        color: e.color,
      };
    });

    return locations.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (l) =>
            l.locationName === value.locationName && l.color === value.color,
        ),
    );
  }

  // Display events in events timezone instead of client local timezone
  async function getEventsTimeZone() {
    const response = await fetch(eventsURL);
    const events = await response.json();
    const eventsTimezone = events[0];

    return eventsTimezone;
  }

  async function compareTimezones(eventsLocalTimezone, localTimezone) {
    const eventsTimezone = await eventsLocalTimezone;

    if (localTimezone != eventsTimezone.timezone_IANA) {
      timezoneMessage(eventsTimezone);
    }
  }

  const timezoneMessage = (eventsTimezone) => {
    const element = document.querySelector("#wrapper-timezone");

    const message = document.createElement("p");
    message.style.order = 1;
    message.innerHTML = `Times displayed in ${eventsTimezone.timezone_name} timezone`;

    element.prepend(message);
  };

  async function displayLocationsFilter() {
    const filterDiv = document.createElement("div");
    const locations = await fetchLocations();

    const markup = `
      <div class="locations-filter-container">
        <span class="filter-by-location">Filter by location:</span>
        <ul class="locations-filter-list">
        ${locations
          .map(
            (loc) => `
            <li style="padding: 0 1rem;">
              <input type="checkbox" class="checkbox calendar-location-checkbox" style="background-color: ${loc.color}; border-color:white;" checked="true" value="${loc.locationName}">
              <label style="font-weight: normal;">
                ${loc.locationName}
              </label>
            </li>
          `,
          )
          .join("")}
        </ul>
      </div>
    `;

    filterDiv.innerHTML = markup;

    const checkBoxes = filterDiv?.querySelectorAll(".checkbox");

    checkBoxes.forEach((cb) => {
      cb.addEventListener("click", () => {
        showHideEvents(cb);
      });
    });

    calendarEl.append(filterDiv);
  }

  function getAllEvents() {
    return calendar.getEvents();
  }

  function showHideEvents(checkBox) {
    const events = getAllEvents();

    if (checkBox.checked === true) {
      events.forEach((event) => {
        if (event.extendedProps.location_name === checkBox.value) {
          event.setProp("display", "block");
        }
      });
    } else {
      events.forEach((event) => {
        if (event.extendedProps.location_name === checkBox.value) {
          event.setProp("display", "none");
        }
      });
    }
  }
};

function convertEventsToLocationTZ(events) {
  return events.map((event) => ({
    ...event,
    start: convertDateTimeToLocationTZ(
      event.start,
      event.timezone_IANA,
      true,
    ).toISO(),
    end: convertDateTimeToLocationTZ(
      event.end,
      event.timezone_IANA,
      true,
    ).toISO(),
  }));
}

function convertDateTimeToLocationTZ(dateTime, timezone, keepLocalTime) {
  const luxonDate = DateTime.fromISO(dateTime, { zone: timezone });
  const newDatetime = luxonDate.setZone("UTC", {
    keepLocalTime: keepLocalTime,
  });

  return newDatetime;
}

function init() {
  if (document.querySelector(".events-calendar") !== null) {
    addCalendarEventsToPage();
  }
}

export default init;
