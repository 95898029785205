import { useReducer, useRef } from "react";

import DirectUpload from "@/lib/DirectUpload/DirectUpload";

const initialState = {};

function reducer(fileUploads, { type, uploadId, payload }) {
  switch (type) {
    case "add": {
      const toAdd = {
        [uploadId]: {
          id: uploadId,
          name: payload.file.name,
          progress: {
            total: payload.file.size,
            progress: 0.0,
            estimated: null,
            rate: null,
          },
          blobId: null,
          directUpload: payload,
          complete: false,
          error: null,
        },
      };

      return { ...fileUploads, ...toAdd };
    }
    case "update":
      return {
        ...fileUploads,
        [uploadId]: {
          ...fileUploads[uploadId],
          ...payload,
        },
      };
    case "remove": {
      const { [uploadId]: removed, ...updatedFileUploads } = fileUploads;
      removed.directUpload.abort();

      return updatedFileUploads;
    }
    default:
      throw new Error(`Unknow action type ${type}`);
  }
}

function useDirectUploads(url) {
  // Using useRef to keep value across re-renders.
  const id = useRef(0);

  const [fileUploads, dispatch] = useReducer(reducer, initialState);

  const addUpload = (uploadId, directUpload) =>
    dispatch({ type: "add", uploadId, payload: directUpload });

  const updateUpload = (uploadId, data) =>
    dispatch({ type: "update", uploadId, payload: data });

  const removeUpload = (uploadId) => dispatch({ type: "remove", uploadId });

  const onUploadProgress = (uploadId, progress) =>
    updateUpload(uploadId, { progress });

  const onUploadComplete = (uploadId, blobId) =>
    updateUpload(uploadId, { blobId, complete: true });

  const onUploadError = (uploadId, error) => updateUpload(uploadId, { error });

  const startUpload = async (file, extraParams = {}) => {
    const directUpload = new DirectUpload(file, url, extraParams);

    const currentId = id.current++;

    addUpload(currentId, directUpload);

    try {
      const blobId = await directUpload.upload((progress) =>
        onUploadProgress(currentId, progress)
      );

      // upload returns false if it's cancelled.
      if (blobId) onUploadComplete(currentId, blobId);
    } catch (error) {
      onUploadError(currentId, error.message);
    }
  };

  return {
    fileUploads: Object.values(fileUploads),
    startUpload,
    removeUpload,
  };
}

export default useDirectUploads;
