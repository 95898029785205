import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import config from "@/config";
import FileSize from "@/components/FileSize";
import Icon from "@/components/Icon";
import useRecaptcha from "@/hooks/useRecaptcha";
import useDirectUploads from "@/hooks/useDirectUploads";
import FileUpload from "./FileUpload";

const publicUploadsUrl = `${config.baseApiUrl}/public_uploads`;

const maxSize = 10 * 1024 * 1024; // 10MiB

const accept = {
  "image/*": [".jpeg", ".jpg", ".png", ".gif"],
  "video/*": [".mp4", ".mov", ".avi"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
};

const extensions = Object.values(accept).flat().join(", ");

const baseStyle = {
  width: "100%",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: "#ced4da",
  borderRadius: "0.25rem",
  color: "#495057",
  textAlign: "center",
  padding: "1.5rem 0",
  cursor: "pointer"
};

// Bootstrap green
const acceptStyle = {
  color: "#155724",
  borderColor: "#c3e6cb", 
  backgroundColor: "#d4edda",
};

// Bootstrap red
const rejectStyle = {
  color: "#721c24",
  borderColor: "#f5c6cb",
  backgroundColor: "#f8d7da",
}

function UploadInput({ name, multiple, maxUploads, formId }) {
  const { loading, getRecaptchaToken } = useRecaptcha();
  const { fileUploads, startUpload, removeUpload } = useDirectUploads(publicUploadsUrl);  

  const filesLeft = maxUploads - fileUploads.length;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file) => {
      const recaptchaToken = await getRecaptchaToken("form_public_upload");

      startUpload(file, { metadata: { form_id: formId }, recaptcha_token: recaptchaToken });
    });
  }, [getRecaptchaToken, startUpload]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxSize,
    multiple,
    accept,
    maxFiles: filesLeft,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragAccept,
    isDragReject,
  ]);

  if (loading) return "Loading...";

  const uploadIcon = <Icon icon="fa-solid fa-cloud-arrow-up" />;

  let text;

  if (isDragActive) {
    text = isDragAccept ? <span>Drop the files here...</span> : <span>Invalid selection!</span>;
  } else {
    if (multiple) {
      text = <span>{uploadIcon} Drop some files here or click to select ({filesLeft} max)</span>;
    } else {
      text = <span>{uploadIcon} Drop file here or click to select</span>;
    }
  }

  text = (
    <p className="so-m-0">
      {text}
      <span className="so-block so-text-sm">
        Maximum size: <FileSize size={maxSize} base={2} />
      </span>
      <span className="so-block so-text-sm">
        Formats: {extensions}
      </span>
    </p>
  );

  return (
    <div className="so-forms-upload">
      {filesLeft > 0 ? (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          { text }
        </div>
      ) : null}

      {fileUploads.map((fu) => (
        <FileUpload
          key={fu.id}
          fieldName={name}
          fileUpload={fu}
          multiple={multiple}
          remove={() => removeUpload(fu.id)}
        />
      ))}
    </div>
  );
}

export default UploadInput;
