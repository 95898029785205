import addScriptTag from "./addScriptTag";

const scripts = {};

const defaultOptions = {
  async: true,
  defer: true,
};

function getGlobalLib(name, url, options = defaultOptions) {
  if (scripts[name]) return scripts[name];

  if (window[name]) {
    scripts[name] = window[name];
    return scripts[name];
  }

  const promise = new Promise(async (resolve, reject) => {
    try {
      let script = await addScriptTag(url, options, document.body);
      scripts[name] = window[name];
      resolve(scripts[name]);

      // Clean up.
      document.body.removeChild(script);
      script = undefined;
    } catch (error) {
      reject(error);
    }
  });

  scripts[name] = promise;

  return scripts[name];
}

export default getGlobalLib;
