import { useEffect, useState } from "react";

import apiRequest from "@/lib/api";
import { getDomain } from "@/lib/esite";

function Gallery({ filter }) {
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadGallery();
  }, []);

  const loadGallery = async () => {
    setLoading(true);
    const response = await apiRequest(`${getDomain()}/website_gallery`);
    if (!response) return;

    if (response.data) {
      const gallery = parseGalleryItems(response.data);
      setImages(gallery);
    }

    setLoading(false);
  };

  function parseGalleryItems(data) {
    const { html, site_dir } = data;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const anchorElements = tempDiv.querySelectorAll(".gallery-items a");

    const parsedItems = Array.from(anchorElements).map((anchor) => {
      const src = anchor
        .getAttribute("href")
        ?.replace("%path%", `/${site_dir}`);
      const aClasses = anchor.className;
      const category = aClasses.split(" ").pop();

      const img = anchor.querySelector("img");
      const imgClasses = img ? img.className : "";
      const altText = img ? img.getAttribute("alt") : "";

      return { src, category, aClasses, imgClasses, altText };
    });

    return parsedItems.filter(
      (img) => img.category === filter.toLowerCase().replace(/\s+/g, "-"),
    );
  }

  const placeholderClasses =
    "so-animate-pulse so-bg-slate-200 so-h-72 md:so-h-44 lg:so-h-60 xl:so-h-72 so-w-full lg:so-w-36 so-grow so-my-4 md:so-mx-4";

  return (
    <>
      {loading ? (
        <div className="so-flex so-flex-col md:so-flex-row so-justify-between">
          <div className={placeholderClasses}></div>
          <div className={placeholderClasses}></div>
          <div className={placeholderClasses}></div>
        </div>
      ) : (
        images && (
          <div className="gallery-container">
            <div className="gallery-items row justify-content-center">
              {images.map((img) => (
                <a href={img.src} className={img.aClasses}>
                  <img
                    className={img.imgClasses}
                    data-src={img.src}
                    src={img.src}
                    no-modal=""
                    alt={img.altText}
                  />
                </a>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
}

export default Gallery;
