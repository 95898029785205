import { getSettings } from "@/lib/esite";
import addScriptTag from "@/lib/addScriptTag";

async function init() {
  if (window.umami) return;
  const { umami } = await getSettings();

  const trackingCode = umami?.tracking_code;
  if (!trackingCode) return;

  addScriptTag(umami.script_url, { "data-website-id": trackingCode, defer: false }, document.head);
}

export default init;
