import { useEffect, useState } from "react";

import getGlobalLib from "@/lib/getGlobalLib";

// Using an object directly as the function arg causes infinite re-renders.
const defaultOptions = {};

function useGlobalLib(name, url, options = defaultOptions) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lib, setLib] = useState({});

  // Options object has to be detructured or we get an infinite loop
  // due to object referential inequality in useEffect deps.
  const { async, defer } = options;

  useEffect(() => {
    async function getLib() {
      try {
        const lib = await getGlobalLib(name, url, { async, defer });

        // lib has to be in an object because it may be a function
        // which react will think is a callback to be executed immediatelly.
        setLib({ [name]: lib });
      } catch (_e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    getLib();
  }, [name, url, async, defer]);

  return { loading, error, [name]: lib[name] };
}

export default useGlobalLib;
