import React from "react";
import { createRoot } from "react-dom/client";

import SitePosts from "./SitePosts";

function init() {
  if (document.querySelector(".site-posts-widget") !== null) {
    const container = document.querySelector(".site-posts-widget");
    const root = createRoot(container);

    const tags = container.getAttribute("data-tags");

    root.render(<SitePosts tags={tags} />);
  }
}

export default init;
