import { createRoot } from "react-dom/client";

import Gallery from "./Gallery";

function init() {
  if (document.querySelector(".new-gallery") !== null) {
    const galleryPlaceholder = document.querySelector(".new-gallery");
    const folderFilter = galleryPlaceholder.dataset.folder;

    const root = createRoot(galleryPlaceholder);

    root.render(<Gallery filter={folderFilter} />);
  }
}

export default init;
