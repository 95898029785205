import { useState, useEffect } from "react";
import { getDomain } from "@/lib/esite";

import apiRequest from "@/lib/api";
import Post from "./Post";

function SitePosts({ tags }) {
  const [posts, setPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [page, setPage] = useState(1);
  const [HasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadMorePosts();
  }, []);

  const addTags = tags ? `&tags=${tags}` : "";

  const loadMorePosts = async () => {
    setIsFetching(true);

    const response = await apiRequest(
      `${getDomain()}/site_posts?page=${page}${addTags}`
    );
    if (!response) return;

    const fetchedPosts = response.data;

    setPosts((prevPosts) => {
      return [...new Set([...prevPosts, ...fetchedPosts.map((p) => p)])];
    });
    setPage((prevPageNumber) => prevPageNumber + 1);
    setHasMore(fetchedPosts.length > 0);
    setIsFetching(false);
  };

  return (
    <div>
      <div className="so-grid so-grid-cols-1 md:so-grid-cols-2 lg:so-grid-cols-3 so-gap-4 so-p-4 md:so-px-20 site-post-container">
        {posts && posts?.map((post, index) => <Post key={index} post={post} />)}
      </div>

      {HasMore && (
        <div className="so-w-full so-text-center site-post-loading-more">
          <button
            onClick={loadMorePosts}
            className="so-border-none so-text-sm so-font-bold so-py-1 so-px-6 so-rounded-md so-bg-white so-text-black site-post-button"
          >
            {!isFetching && HasMore ? "Load More" : "Loading..."}
          </button>
        </div>
      )}
    </div>
  );
}

export default SitePosts;
