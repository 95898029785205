import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import getGlobalLib from "@/lib/getGlobalLib";
import apiRequest from "@/lib/api";
import Popup from "./PopupComponent";

async function init() {
  let showPopupPages = [
    "/",
    "/index.htm",
    "/index.html",
    "/home.htm",
    "/home.html",
  ];

  // Allows the site to override in which pages the pop-up will show.
  if (window.popupPages) showPopupPages = window.popupPages;

  if (!showPopupPages.includes(window.location.pathname)) return;

  // We're using jQuery and bootstrap modals to keep compatibility.
  const $ = await getGlobalLib(
    "$",
    "https://code.jquery.com/jquery-3.6.1.min.js",
  );
  const response = await apiRequest("active-popup");

  if (!response) return;

  const { id, content, display_times } = response.data;

  const key = `spillover_popup_displayed_times_${id}`;
  const displayedTimes = Number(window.localStorage.getItem(key));

  if (display_times && displayedTimes >= display_times) return;

  window.localStorage.setItem(key, displayedTimes + 1);

  const html = renderToStaticMarkup(
    <React.StrictMode>
      <Popup content={content} />
    </React.StrictMode>,
  );

  document.body.insertAdjacentHTML("beforeend", html);

  $("#popup").modal("show");
  // We need the click below to keep compatibility with Bootstrap 4.5v Modal
  $("#popup").on("click", function () {
    $("#popup").modal("hide");
  });
}

export default init;
