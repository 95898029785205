const env = import.meta.env.MODE; // Vite replaces this
const baseApiUrl = import.meta.env.VITE_API_BASE_URL;
const recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY;

const config = {
  env,
  baseApiUrl,
  recaptchaKey,
};

export default config;
