import Icon from "@/components/Icon";

function ProgressIcon({ fileUpload }) {
  let props = {};

  if (fileUpload.error) {
    props.icon = "fa-solid fa-warning";
    props.className = "so-mr-1 so-text-amber-600";
  } else if (fileUpload.complete) {
    props.icon = "fa-solid fa-check";
    props.className = "so-mr-1 so-text-green-600";
  } else {
    props.icon = "fa-solid fa-spinner";
    props.spin = true;
    props.className = "so-mr-1 so-text-neutral-600";
  }

  return <Icon {...props} />;
}

export default ProgressIcon;
