import React from "react";
import { createRoot } from "react-dom/client";

import { addReferrerToForm, saveReferrer } from "./referrer";
import UploadInput from "./UploadInput";

function render(container, props) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <UploadInput {...props} />
    </React.StrictMode>
  );

  return { container, root, props };
}

function init() {
  saveReferrer();

  // Replace all form file inputs with our react component.
  document.querySelectorAll("form.cmsform").forEach((form) => {
    // Extract formId from original action.
    const actionParts = form.getAttribute("action").split("/");
    const formId = actionParts[actionParts.length - 2];

    addReferrerToForm(form);

    let renderData = [];

    form.querySelectorAll("input[type=file]").forEach((input) => {
      const wrapper = input.parentNode;
      const multiple = input.hasAttribute("multiple");
      const name = input.getAttribute("name");
      const maxUploads = input.dataset.maxUploads ? parseInt(input.dataset.maxUploads) : 1;

      input.remove();

      const container = document.createElement("div");
      wrapper.append(container);

      const props = {
        name,
        multiple,
        maxUploads,
        formId,
      };

      const rendered = render(container, props);

      renderData.push(rendered);
    });

    // The insane way I found of clearing the uploads when the form is submitted:
    // Unmount and re-render component when form class changes to include "so-form-submitted".
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.classList.contains("so-form-submitted")) {
          renderData = renderData.map((rendered) => {
            rendered.root.unmount();
            return render(rendered.container, rendered.props);
          });
        }
      });
    });

    observer.observe(form, {
      attributes: true,
      attributeFilter: ["class"],
    });
  });
}

export default init;
