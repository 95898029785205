function saveReferrer() {
  // Save referrer in session storage and remove from URL.
  if (window.sessionStorage && URL) {
    const url = new URL(window.location.href);

    if (!url.searchParams.has("r")) return;

    window.sessionStorage.setItem("spillover-referrer", url.searchParams.get("r"));

    url.searchParams.delete("r");

    window.history.replaceState({}, document.title, url.href);
  }
}

function addReferrerToForm(form) {
  if (window.sessionStorage) {
    const referrer = window.sessionStorage.getItem("spillover-referrer");

    if (!referrer) return;

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "_spillover_referrer";
    input.value = referrer;

    form.appendChild(input);
  }
}

export { saveReferrer, addReferrerToForm };
